import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

export enum TotpType {
  VerifyEmail = 1,
  VerifyForgotPassword = 2,
  Validate = 3,
}

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  inviteUser(email: string, firstName: string, lastName: string, stateIds: number[]) {
    return this.http.post('account/invite', { email, firstName, lastName, stateIds });
  }

  resendInvite(inviteGuid: any) {
    return this.http.post(`account/resend-invite${inviteGuid}`, {});
  }

  cancelInvite(inviteGuid: string) {
    return this.http.post(`account/cancel-invite/${inviteGuid}`, {});
  }

  registerUserTotp(model: any) {
    return this.http.post('account/register', model);
  }

  getInviteDetails(inviteGuid: string) {
    return this.http.get(`account/invite/${inviteGuid}`);
  }

  forgotPassword(req: { email: string; }) {
    return this.http.post('account/forgot-password', req);
  }

  resetPassword(email: string, password: string, uniqueIdentifier: string) {
    return this.http.post('account/reset-password', { email, password, uniqueIdentifier });
  }
}
